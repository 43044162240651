<script>
import axios from "axios";

export default {
  name: "StudentApplicationDetails",
  data() {
    return {
      studentApplication: null,
      files: {},
      errorMsg: null,
    };
  },
  mounted() {
    this.getMyApplication();
  },
  methods: {
    getMyApplication() {
      axios.get("applications/my-application").then((res) => {
        this.studentApplication = res.data;
      });
    },
    updateDocuments() {
      var incorrectFilesCount = 0;

      for (var i = 0; i < this.studentApplication.documents.length; i++) {
        if (this.studentApplication.documents[i].status === "rejected") {
          incorrectFilesCount++;
        }
      }

      if (Object.entries(this.files).length !== incorrectFilesCount) {
        this.errorMsg = this.$t("not_all_files_are_uploaded");
        return;
      } else {
        this.errorMsg = null;
      }

      var formData = new FormData();

      for (const [key, value] of Object.entries(this.files)) {
        formData.append(key, value);
      }

      axios
        .patch(`applications/${this.studentApplication.id}/documents`, formData)
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t("documents_updated_successfully"),
            type: "is-success",
            position: "is-top-right",
          });
          this.$router.push({
            name: "Home",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$buefy.toast.open({
            message: "Произошла ошибка",
            type: "is-danger",
            position: "is-top-right",
          });
        });

      return;
    },
  },
};
</script>

<template>
  <div v-if="studentApplication">
    <div class="has-text-centered">
      <h1 class="is-size-4">
        {{ $t("application") }} (№{{ studentApplication.id }})
      </h1>
      <h2 class="is-size-6">
        {{ localize(studentApplication.category, "title") }}
      </h2>
      <p>Дата подачи: {{ studentApplication.sent_date | formatDate }}</p>
      <p>Статус заявления: {{ $t(studentApplication.status) }}</p>
    </div>
    <div class="columns is-centered">
      <div class="column is-8">
        <b-table :data="studentApplication.documents">
          <b-table-column field="title" v-slot="props">
            <span class="is-size-6">
              {{ localize(props.row.document_type, "title") }}
            </span>
          </b-table-column>
          <b-table-column field="status" v-slot="props" width="200px">
            <div
              class="is-flex is-align-items-center"
              :set="
                (statusClass = getCSSClassAndIconForDocumentStatus(
                  props.row.status
                ))
              "
            >
              <b-icon
                class="mr-4"
                :type="statusClass[1]"
                :icon="statusClass[2]"
                size="is-medium"
              >
              </b-icon>
              <span :class="statusClass[0]">
                {{ $t(props.row.status) }}
              </span>
            </div>
          </b-table-column>

          <b-table-column v-slot="props">
            <div class="is-flex is-justify-content-end">
              <a
                v-if="props.row.status !== 'rejected'"
                class="button is-primary is-small"
                :href="props.row.uploaded_file"
                target="_blank"
              >
                Посмотреть файл
              </a>
              <b-upload v-else class="file-label" v-model="files[props.row.id]">
                <span class="file-cta">
                  <b-icon class="file-icon" icon="upload"></b-icon>
                  <span class="file-label">
                    <template v-if="files[props.row.id]">
                      {{ files[props.row.id].name }}
                    </template>
                    <template v-else> Загрузить файл </template>
                  </span>
                </span>
              </b-upload>
            </div>
          </b-table-column>
        </b-table>
        <div
          v-if="studentApplication.commentary"
          class="has-text-centered mt-3"
        >
          <h3 class="is-size-5">Комментарий:</h3>
          <p class="mt-3">
            {{ studentApplication.commentary }}
          </p>
        </div>
        <div v-if="Object.keys(files).length" class="my-5 has-text-centered">
          <b-button type="is-primary" @click="updateDocuments()" expanded
            >Сохранить</b-button
          >
          <p v-if="errorMsg" class="mt-3 has-text-danger">
            {{ errorMsg }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
